import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import './style.css';

const Avatar = () => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "me-avatar.png" }) {
          childImageSharp {
            fluid(maxWidth: 200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );

  const videoAvatarRef = useRef<HTMLVideoElement>(null);

  const handleMouse = () => {
    const video = videoAvatarRef.current;

    video?.play();
  };

  useEffect(() => {
    document.addEventListener('click', handleMouse);

    return () => {
      document.removeEventListener('click', handleMouse);
    }
  }, []);

  return (
    <>
      <div className="avatar-bg" />
      <div className="avatar-video">
        <video ref={videoAvatarRef} src="/me.mp4" width="126" height="126" />
      </div>
    </>
  );
};

export default Avatar;
